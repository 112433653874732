const config = {
  siteName: 'Wally',
  title: 'Wally - Link your wallet to your socials',
  description: 'Wally links your Ethereum wallet to your social profiles and gives you an easy way to show off and verify all of your sweet assets.',
  twitter: {
    title: 'Wally - Link your wallet to your socials',
    description:
      'Wally links your Ethereum wallet to your social profiles and gives you an easy way to show off and verify all of your sweet assets.',
    username: '@wallylink',
  },
};

export default config;
