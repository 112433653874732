import { useState, createContext, useContext } from 'react';
import axios from 'axios';
import { logEvent } from '../lib/ga';

const VerificationsContext = createContext(null);
export const useVerifications = () => useContext(VerificationsContext);

export const VerificationsProvider = ({ children }) => {
  const [verifications, setVerifications] = useState([]);

  const createProfile = async (data) => {
    data.date = new Date();

    await axios({
      url: `/api/profile`,
      method: 'POST',
      data,
    });
    logEvent({
      action: 'createProfile',
    });
  };

  const getVerifications = async ({ address, id }) => {
    let url = ``;

    if (id) {
      url = `/api/verifications?id=${id}`;
    } else if (address) {
      url = `/api/verifications?address=${address}`;
    }

    const response = await axios({
      url,
      method: 'GET',
    });

    const data = response?.data || {};
    setVerifications(data);
  };

  const updateProfile = async (data) => {
    await axios({
      url: `/api/profile`,
      method: 'PUT',
      data,
    });
    await getVerifications({ id: data.newId });
    logEvent({
      action: 'updateProfile',
    });
  };

  const verifyTwitter = async (data) => {
    if (!data?.twitter?.handle) {
      throw new Error('Must be authenticated to Twitter to verify');
    }

    await axios({
      url: `/api/verify/twitter`,
      method: 'PUT',
      data,
    });
    await getVerifications({ id: data.id });
    logEvent({
      action: 'verifyTwitter',
    });
  };

  const verifyDiscord = async (data) => {
    if (!data?.discord?.user?.username) {
      throw new Error('Must be authenticated to Discord to verify');
    }

    await axios({
      url: `/api/verify/discord`,
      method: 'PUT',
      data,
    });
    await getVerifications({ id: data.id });
    logEvent({
      action: 'verifyDiscord',
    });
  };

  // ! not currently being used
  const verifySignedMessage = async (type = 'Twitter', handle, signature) => {
    if (!handle) {
      throw new Error('Must provide a handle');
    }

    if (!signature) {
      throw new Error('Must provide a signature');
    }

    const message = `${type} Handle: ${handle}`;

    const { data } = await axios({
      url: `/api/verify/signed-message?signature=${signature}&message=${message}`,
      method: 'GET',
    });

    logEvent({
      action: 'verifySignedMessage',
    });

    return data;
  };

  return (
    <VerificationsContext.Provider
      value={{
        verifications,
        createProfile,
        updateProfile,
        verifyTwitter,
        getVerifications,
        verifyDiscord,
      }}
    >
      {children}
    </VerificationsContext.Provider>
  );
};
