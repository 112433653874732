import { useState, createContext, useContext } from 'react';
import axios from 'axios';

const MoralisContext = createContext(null);
export const useMoralis = () => useContext(MoralisContext);

export const MoralisProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nfts, setNFTs] = useState([]);
  const [count, setCount] = useState(0);

  const getNFTs = async (address) => {
    try {
      setError(null);
      setLoading(true);

      if (process.env.NEXT_PUBLIC_TEST_WALLET_ADDRESS) {
        address = process.env.NEXT_PUBLIC_TEST_WALLET_ADDRESS;
      }

      const { data } = await axios({
        url: `/api/nfts?address=${address}`,
        method: 'GET',
        data,
      });

      setCount(data?.result?.length || 0);

      let numberBeforeStart = 26;
      if (window.innerWidth < 768) {
        numberBeforeStart = 14;
      } else if (window.innerWidth < 1024) {
        numberBeforeStart = 24;
      }

      // add nfts to middle of array if user doesn't have that many NFTs
      // prettier-ignore
      data.result = data.result.length < numberBeforeStart ? [...Array(numberBeforeStart - data.result.length).fill({ empty: true }), ...data.result] : data.result;
      data.result = data.result.length < 200 ? [...data.result, ...Array(200 - data.result.length).fill({ empty: true })] : data.result;

      setNFTs(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(error.message);
    }
  };

  return (
    <MoralisContext.Provider
      value={{
        getNFTs,
        nfts,
        loading,
        error,
        count,
      }}
    >
      {children}
    </MoralisContext.Provider>
  );
};
