import React from 'react';
import { MoralisProvider } from '../hooks/useMoralis';
import WagmiProvider from '../hooks/useWagmi';
import { TwitterProvider } from '../hooks/useTwitter';
import { VerificationsProvider } from '../hooks/useVerifications';
import { DiscordProvider } from '../hooks/useDiscord';

function TheProviders({ children }) {
  return (
    <MoralisProvider>
      <WagmiProvider>
        <DiscordProvider>
          <TwitterProvider>
            <VerificationsProvider>{children}</VerificationsProvider>
          </TwitterProvider>
        </DiscordProvider>
      </WagmiProvider>
    </MoralisProvider>
  );
}

export default TheProviders;
