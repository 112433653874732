import { Toaster } from 'react-hot-toast';
import theme from '../tailwind.config';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;

import '../styles/globals.css';
import TheProviders from '../components/TheProviders';
import Header from '../components/Header';

function MyApp({ Component, pageProps }) {
  return (
    <TheProviders>
      <div className='absolute inset-0'>
        <Header />
        <Component {...pageProps} />
      </div>
      <Toaster
        position='bottom-center'
        reverseOrder={false}
        toastOptions={{
          // Define default options
          duration: 3000,
          style: {
            background: theme.theme.extend.colors?.bg,
            color: '#fff',
            fontFamily: theme.theme.fontFamily.body,
          },
        }}
      />
    </TheProviders>
  );
}

export default MyApp;
