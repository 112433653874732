module.exports = {
  content: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}'],
  theme: {
    fontFamily: {
      body: ['Manrope', 'sans-serif'],
    },
    fontWeight: {
      normal: 400,
      semibold: 600,
      bold: 700,
      extrabold: 800,
    },
    borderRadius: {
      4: '4px',
      15: '15px',
      16: '16px',
      30: '30px',
      full: '99999px',
    },
    boxShadow: {
      card: '0px 25px 50px rgba(43, 22, 151, 0.75)',
    },
    letterSpacing: {
      0.5: '0.5px',
      1: '1px',
      2: '2px',
    },
    backgroundImage: {
      vignette: 'radial-gradient(64.59% 64.59% at 50% 50%, rgba(196, 196, 196, 0) 54.17%, #1d1d1d 100%)',
      accent: 'linear-gradient(270deg, #5AFFCC 0%, #38FEFE 100%)',
    },
    extend: {
      colors: {
        primary: {
          500: '#5AFFCC',
          600: '#38FEFE',
        },
        secondary: {
          500: '#7B61FF',
          700: '#4824FF',
        },
        bg: '#07071B',
        'bg-input': '#0B0B29',
        'border-input': '#1A1A55',
      },
    },
  },
  plugins: [require('@tailwindcss/typography'), require('@tailwindcss/forms')],
};
