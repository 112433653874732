import { useEffect, useState, createContext, useContext } from 'react';
import axios from 'axios';

const DiscordContext = createContext(null);
export const useDiscord = () => useContext(DiscordContext);

export const DiscordProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const connect = async () => {
    location.href = `https://discord.com/api/oauth2/authorize?client_id=${process.env.NEXT_PUBLIC_DISCORD_CLIENT_ID}&redirect_uri=${process.env.NEXT_PUBLIC_DISCORD_OAUTH_CALLBACK_URL}&response_type=code&scope=identify`;
  };

  const verifyToken = async (code) => {
    setError(null);
    setLoading(true);

    try {
      const response = await axios({
        url: `/api/discord/auth?code=${code}`,
        method: 'POST',
      });

      const token = response?.data?.access_token;
      if (token) {
        setConnected(true);
        getUser(token);
      }
    } catch (error) {
      console.error(error);
      setError(error);
      setConnected(false);
    } finally {
      setLoading(false);
    }
  };

  const getUser = async (token) => {
    try {
      const response = await axios({
        url: `/api/discord/user?token=${token}`,
        method: 'POST',
      });
      setUser(response?.data);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  return <DiscordContext.Provider value={{ connect, connected, verifyToken, error, loading, getUser, user }}>{children}</DiscordContext.Provider>;
};
