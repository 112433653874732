import { useEffect, useState, createContext, useContext } from 'react';
import axios from 'axios';

const TwitterContext = createContext(null);
export const useTwitter = () => useContext(TwitterContext);

export const TwitterProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [username, setUsername] = useState(false);

  const connect = async () => {
    const response = await axios({
      url: `/api/twitter/auth`,
      method: 'POST',
    });
    location.href = response.data.redirectUrl;
  };

  const verifyToken = async ({ oauth_token: oauthToken, oauth_verifier: oauthVerifier }) => {
    const response = await axios({
      url: `/api/twitter/verify`,
      method: 'GET',
      params: {
        oauth_token: oauthToken,
        oauth_verifier: oauthVerifier,
      },
    });

    if (response?.data?.screen_name) {
      setConnected(true);
      setUsername(response.data.screen_name);
    } else {
      setUsername(null);
      setConnected(false);
    }
  };

  return <TwitterContext.Provider value={{ connect, connected, username, verifyToken }}>{children}</TwitterContext.Provider>;
};
